/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserSearchLine, RiTeamLine, RiUserStarLine } from "react-icons/ri"

const FeedbackTemplateDownloadPage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="360-Degree Feedback Template | Comprehensive Performance Evaluation Tool"
        description="Download our 360-degree feedback template for comprehensive employee evaluations from multiple stakeholders. Get insights from managers, peers, and direct reports."
        keywords={[
          "360-degree feedback template",
          "multi-rater feedback form",
          "comprehensive performance evaluation",
          "stakeholder feedback template",
          "peer assessment form",
          "employee review template",
          "feedback collection tool",
          "performance appraisal template",
          "holistic employee evaluation",
          "multi-source feedback"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/performance-reviews" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Performance Reviews Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              360-Degree Feedback Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A comprehensive evaluation tool that gathers feedback from multiple stakeholders including 
              managers, peers, direct reports, and self-assessment to provide a holistic view of performance.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Template
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main content */}
      <div className="printable-content" sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        '@media print': {
          padding: '0',
          fontSize: '12pt'
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          bg: 'white',
          p: [3, 4],
          borderRadius: '8px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
          '@media print': {
            boxShadow: 'none',
            padding: '0'
          }
        }}>
          {/* Introduction Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Introduction to 360-Degree Feedback
            </h2>
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              360-degree feedback, also known as multi-rater feedback, is a comprehensive assessment method 
              that gathers input from multiple sources, including managers, peers, direct reports, and self-evaluation. 
              This approach provides a well-rounded view of an employee's performance, skills, and behaviors.
            </p>
            <p sx={{ lineHeight: 1.6 }}>
              This template provides a structured framework for collecting and analyzing 360-degree feedback, 
              helping organizations implement effective performance evaluation processes that drive employee 
              development and organizational success.
            </p>
          </section>

          {/* Template Preview */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Template Preview
            </h2>
            
            <div sx={{
              border: '1px solid',
              borderColor: 'muted',
              borderRadius: '6px',
              p: 3,
              mb: 4
            }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 700,
                mb: 3,
                color: 'text'
              }}>
                360-Degree Feedback Form
              </h3>
              
              <div sx={{ mb: 4 }}>
                <h4 sx={{ 
                  fontSize: '1rem', 
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Employee Information
                </h4>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mb: 3
                }}>
                  <div sx={{ mb: 2 }}>
                    <div sx={{ 
                      display: 'block',
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      mb: 1
                    }}>
                      Employee Name:
                    </div>
                    <div sx={{
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2,
                      bg: '#f8f9fa'
                    }}>
                      [Employee Name]
                    </div>
                  </div>
                  
                  <div sx={{ mb: 2 }}>
                    <div sx={{ 
                      display: 'block',
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      mb: 1
                    }}>
                      Position/Title:
                    </div>
                    <div sx={{
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2,
                      bg: '#f8f9fa'
                    }}>
                      [Position]
                    </div>
                  </div>
                  
                  <div sx={{ mb: 2 }}>
                    <div sx={{ 
                      display: 'block',
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      mb: 1
                    }}>
                      Department:
                    </div>
                    <div sx={{
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2,
                      bg: '#f8f9fa'
                    }}>
                      [Department]
                    </div>
                  </div>
                  
                  <div sx={{ mb: 2 }}>
                    <div sx={{ 
                      display: 'block',
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      mb: 1
                    }}>
                      Review Period:
                    </div>
                    <div sx={{
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2,
                      bg: '#f8f9fa'
                    }}>
                      [Start Date] to [End Date]
                    </div>
                  </div>
                </div>
                
                <div sx={{ mb: 2 }}>
                  <div sx={{ 
                    display: 'block',
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    mb: 1
                  }}>
                    Relationship to Employee:
                  </div>
                  <div sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 3
                  }}>
                    {["Manager", "Peer", "Direct Report", "Self", "Other"].map((relationship) => (
                      <div key={relationship} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}>
                        <div sx={{
                          width: '16px',
                          height: '16px',
                          border: '1px solid',
                          borderColor: 'muted',
                          borderRadius: '3px'
                        }} />
                        <span sx={{ fontSize: '0.9rem' }}>{relationship}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              
              {/* Additional template content would go here... */}
              <div sx={{ 
                bg: '#f8f9fa',
                p: 3,
                borderRadius: '6px',
                textAlign: 'center',
                border: '1px dashed',
                borderColor: 'muted',
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8
              }}>
                The complete template includes sections for competency ratings, behavioral assessments, 
                open-ended questions, and summary sections. Request the full template to access all content.
              </div>
            </div>
          </section>
          
          {/* Key Competency Areas */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Key Competency Areas
            </h2>
            
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              The 360-degree feedback template evaluates employees across these essential competency areas:
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Job Knowledge & Skills",
                  description: "Technical expertise, professional knowledge, and skill application",
                  icon: <RiUserStarLine />
                },
                {
                  title: "Communication",
                  description: "Verbal, written, and listening skills; clarity and effectiveness",
                  icon: <RiTeamLine />
                },
                {
                  title: "Leadership",
                  description: "Vision, direction, motivation, and development of others",
                  icon: <RiUserSearchLine />
                },
                {
                  title: "Teamwork & Collaboration",
                  description: "Working effectively with others, contributing to team goals",
                  icon: <RiTeamLine />
                }
              ].map((competency) => (
                <div 
                  key={competency.title}
                  sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'muted',
                    bg: '#f8f9fa'
                  }}
                >
                  <div sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    color: 'primary' 
                  }}>
                    <span sx={{ fontSize: '1.5rem', mr: 2 }}>{competency.icon}</span>
                    <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, m: 0 }}>
                      {competency.title}
                    </h3>
                  </div>
                  <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, m: 0 }}>
                    {competency.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Benefits Section */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Benefits of 360-Degree Feedback
            </h2>
            
            <ul sx={{ pl: 0, listStyleType: 'none' }}>
              {[
                "Provides a more comprehensive and balanced perspective on performance",
                "Identifies blind spots that might not be apparent in traditional reviews",
                "Improves self-awareness and encourages personal development",
                "Strengthens communication and teamwork across the organization",
                "Creates a more objective basis for performance evaluation",
                "Helps identify training needs and development opportunities",
                "Enhances accountability and transparency in performance management"
              ].map((benefit) => (
                <li 
                  key={benefit}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3
                  }}
                >
                  <span sx={{ color: 'primary', mr: 2, pt: '3px' }}>
                    <RiCheckLine />
                  </span>
                  <span sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                    {benefit}
                  </span>
                </li>
              ))}
            </ul>
            
            <div sx={{
              bg: '#f8f9fa',
              p: 3,
              mt: 4,
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'muted',
              fontSize: '0.95rem',
              fontStyle: 'italic'
            }}>
              <strong>Note:</strong> For best results, 360-degree feedback should be implemented with clear communication, 
              confidentiality, and proper training for all participants. The feedback should focus on behaviors rather 
              than personality traits and should be linked to specific development actions.
            </div>
          </section>
          
          {/* Print styles */}
          <style jsx>{`
            @media print {
              body {
                font-size: 12pt;
                color: #000;
              }
              h1, h2, h3, h4, h5, h6 {
                break-after: avoid;
              }
              ul, ol, table {
                break-inside: avoid;
              }
              a {
                text-decoration: none;
                color: #000;
              }
              .printable-content {
                padding: 0;
              }
            }
          `}</style>
        </div>
      </div>
    </Layout>
  )
}

export default FeedbackTemplateDownloadPage 